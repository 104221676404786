@import '../../styles/media-queries';

.carousel {
  $carousel: &;

  &__heading {
    border-radius: 4rem;

    padding: 0.8rem 2.4rem;
    font-family: Gilroy, sans-serif;
    font-size: 1.4rem;
    line-height: 1.3;
    text-align: center;

    @include for-screen-up(1024) {
      font-size: 1.6rem;
      line-height: 1.25;
    }

    @include for-screen-up(1400) {
      font-size: 2rem;
      line-height: 1.4;
    }
  }

  &__track {
    margin-bottom: 1.6rem;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: center;

    &_automate {
      #{$carousel}__heading {
        background-color: #c3ffda;
        color: #00a641;
      }
    }

    &_cashback {
      #{$carousel}__heading {
        background-color: #fef2e2;
        color: #f6a940;
      }
    }

    &_balance {
      #{$carousel}__heading {
        background-color: #fdeaed;
        color: #ffabb9;
      }
    }

    &_result {
      #{$carousel}__heading {
        background-color: #c3edff;
        color: #5ba6ff;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__fig {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 27rem;

    @include for-screen-up(440) {
      height: 36rem;
    }

    @include for-screen-up(1024) {
      height: 37rem;
    }

    @include for-screen-up(1200) {
      height: 48rem;
    }

    @include for-screen-up(1400) {
      height: 52rem;
    }
  }

  &__pagination {
    gap: 0.4rem;

    @include for-screen-up(1024) {
      gap: 0.8rem;
    }
  }

  &__page {
    background: var(--bg-color-primary-white);
    border-radius: 50%;
    border: 0.1rem solid var(--bg-color-primary-blue);
    width: 0.8rem;
    height: 0.8rem;
    padding: 0;

    @include for-screen-up(1024) {
      width: 1.2rem;
      height: 1.2rem;
    }

    @include for-screen-up(1400) {
      width: 1.6rem;
      height: 1.6rem;
    }

    &[class*='is-active'] {
      background: var(--bg-color-primary-blue);
    }
  }
}
