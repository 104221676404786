@import '../../styles/media-queries';
@import '../../styles/functions';

.section {
  $section: &;
  background: var(--bg-color-quaternary);
  border-radius: 0 0 6.4rem 6.4rem;

  padding: 13.6rem 1.6rem 8rem;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
  opacity: 0;
  transition: opacity 0.3s ease;

  @include for-screen-up(440) {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    padding: 13.6rem 2.4rem 8rem;
  }

  @include for-screen-up(600) {
    padding: 17.2rem 2.4rem 8rem;
  }

  @include for-screen-up(768) {
    margin-left: -3.2rem;
    margin-right: -3.2rem;
    padding: 17.2rem 3.2rem 8rem;
  }

  @include for-screen-up(1200) {
    margin-left: -4rem;
    margin-right: -4rem;
    padding: 17.2rem 4rem 8rem;
  }

  @include for-screen-up(1400) {
    margin-left: -7.2rem;
    margin-right: -7.2rem;
    padding: 17.2rem 7.2rem 8rem;
  }

  @include for-screen-up(1920) {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    padding: 17.2rem 2.4rem 8rem;
  }

  &_animation-start {
    opacity: 1;
  }

  &__content {
    display: flex;
    width: 100%;
    margin: auto;
    align-items: center;
    gap: 5rem;
    flex-direction: column;

    @include for-screen-up(600) {
      gap: 8rem;
    }

    @include for-screen-up(960) {
      flex-direction: row;
    }

    @include for-screen-up(1400) {
      max-width: 131.9rem;
    }

    @include for-screen-up(1680) {
      max-width: 153.6rem;
    }

    @include for-screen-up(1920) {
      max-width: 175.2rem;
    }
  }

  &__col {
    box-sizing: border-box;
    display: block;
    width: 100%;
    min-height: inherit;
    flex: 0 1 auto;
    justify-content: flex-start;
    position: relative;

    @include for-screen-up(960) {
      flex: 0 1 calc(50% - 4rem);
      min-width: calc(50% - 4rem);
      max-width: calc(50% - 4rem);
    }

    &:first-child {
      flex-direction: column;
    }

    &:last-child {
    }
  }

  &__summary {
    margin-bottom: 3.2rem;
    color: var(--text-color-quaternary);
    font-family: Gilroy, sans-serif;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.4;

    @include for-screen-up(600) {
      margin-bottom: 4rem;
    }

    @include for-screen-up(1024) {
      font-size: 2.4rem;
    }

    @include for-screen-up(1400) {
      font-size: 2.8rem;
    }
  }

  &__auth-links {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @include for-screen-up(440) {
      flex-direction: row;
    }
  }

  &__button-link {
    flex: 0 1 auto;
    width: 100%;

    @include for-screen-up(640) {
      width: auto;
    }
  }

  &__heading {
    margin-bottom: 2.4rem;
    color: var(--text-color-primary);
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 1.36;

    @include for-screen-up(600) {
      margin-bottom: 3.2rem;
      font-size: 4.4rem;
    }

    @include for-screen-up(1024) {
      font-size: 5.6rem;
    }

    @include for-screen-up(1400) {
      font-size: 7rem;
    }
  }

  &__benefits {
    width: 100%;
    height: 100%;
  }

  &__fig {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
