.register {
  &__heading {
    margin: 0 0 4rem;
    color: var(--text-color-primary-black);
    font-size: 2.4rem;
    line-height: 1.36;
  }

  &__summary {
    margin-bottom: 3.8rem;
  }

  &__by-admitad {
    display: inline-flex;
    align-items: center;
    color: var(--bg-color-primary-black);
    font-size: 1.6rem;
    gap: 1.3rem;
    text-decoration: none;

    &-text {
      display: block;
      flex: 1 1 auto;
    }

    &-icon {
      display: block;
      flex: 0 1 4.3rem;

      svg {
        display: block;
      }
    }
  }
}
