@import '../../styles/media-queries';

.heading {
  margin: 0 0 4rem;
  color: var(--text-color-quaternary);
  font-size: 2.4rem;
  line-height: 1.4;
  font-weight: 400;

  &__summary {
    margin-bottom: 3.8rem;
  }

  &__by-admitad {
    display: inline-flex;
    align-items: center;
    color: var(--bg-color-primary-black);
    font-size: 1.6rem;
    gap: 1.3rem;
    text-decoration: none;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__captcha {
    position: absolute;
  }

  &__field {
    width: 100%;
  }

  &__group-links {
    display: flex;
    justify-content: space-between;
  }

  &__group-buttons {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding-top: 1.6rem;

    @include for-screen-up(960) {
      flex-direction: row;
    }
  }

  &__button {
    flex: 1 1 auto;
    width: 100%;

    &:first-child {
      order: 2;

      @include for-screen-up(960) {
        order: 1;
      }
    }

    &:last-child {
      order: 1;

      @include for-screen-up(960) {
        order: 2;
      }
    }

    @include for-screen-up(960) {
      width: 50%;
    }
  }

  &__link {
    color: var(--text-color-primary-blue);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.errors {
  margin-top: 20px;
}
