.home {
  &__about-main {
    margin-bottom: 4rem;
  }

  &__partners {
    margin-bottom: 10rem;

    &:not([class*='is-visible']) {
      height: auto;
      min-height: 4.8rem;
    }
  }

  &__platform {
    margin-bottom: 10rem;

    &:not([class*='is-visible']) {
      min-height: 108rem;
    }
  }

  &__unlimited {
    margin-bottom: 10rem;

    &:not([class*='is-visible']) {
      min-height: 87.3rem;
    }
  }

  &__billing {
    margin-bottom: 10rem;
    &:not([class*='is-visible']) {
      min-height: 137.7rem;
    }
  }

  &__services {
    margin-bottom: 10rem;

    &:not([class*='is-visible']) {
      min-height: 97rem;
    }
  }

  &__cpa {
    margin-bottom: 10rem;

    &:not([class*='is-visible']) {
      min-height: 97rem;
    }
  }
}
