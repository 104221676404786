.texts {
  $texts: &;

  color: var(--text-color-primary-black);
  font-family: Gilroy, sans-serif;
  font-size: 2rem;
  line-height: 1.7;
  text-align: justify;

  &__heading {
    margin: 0 0 1.5rem;
    font-family: Lack, sans-serif;
    font-size: 4.5rem;
    font-weight: 400;
    line-height: 1.36;
    text-align: left;
  }

  h4 {
    margin: 0 0 1.5rem;
    font-family: Lack, sans-serif;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.36;
    text-align: left;
  }

  h6 {
    margin: 0 0 1.5rem;
    font-family: Lack, sans-serif;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.36;
    text-align: left;
  }

  p {
    margin: 0 0 1.5rem;
  }

  p:empty,
  p:has(span:empty) {
    display: none;
  }

  ul {
    & > li {
      margin: 0 0 1.5rem;

      padding-left: 2.5rem;
      position: relative;

      &:before {
        content: '';
        background: var(--bg-color-primary-black);
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        left: 1rem;
        top: 1.5rem;
      }
    }
  }

  ul[style*='list-style'] {
    & > li {
      margin-bottom: 3.4rem;
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  table[class*='table-standard'] {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    font-family: Gilroy, sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;

    th {
      font-weight: bold;
    }

    td,
    th {
      border: 1px solid #d5d9dc;
      padding: 6px 8px;
    }
  }

  [class*='table-standard__cell_align-center'] {
    text-align: center;
  }

  [class*='table-standard__cell_separator'] {
    background: #e7f1fc;
    text-align: center;
  }
}
